<template>
  <div class="all">
    <img src="../assets/logo.png" alt="" class="img mr-5" />
    <div class="box">
      <b-form @submit="onSubmit">
        <p class="title">Sign Up</p>
        <p class="hint mt-2">Please enter your information to create your account.</p>
        <div v-if="isLoading" class="text-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <b-alert v-bind:show="registrationError" variant="danger"
          >Registration failed: {{ registrationMessage }}</b-alert
        >

        <b-alert :show="registrationSuccess" variant="success"
          >Registration success. We've sent you a confirmation email. Please
          click on the link in the email to validate your account. If you did not receive the email, please check your spam folder or 
          contact support at support@altosio.com
          </b-alert
        >
        <div class="row mt-5 rowmargin">
          <b-form-input
            v-model="form.firstName"
            type="text"
            placeholder="First name"
            class="shape profile col-5"
          ></b-form-input>
          <b-form-input
            v-model="form.lastName"
            type="text"
            placeholder="Last name"
            class="shape profile col-5"
          ></b-form-input>
        </div>
        <div class="row mt-4 rowmargin">
          <b-form-input
            v-model="form.email"
            :state="stateOfEmail"
            type="email"
            placeholder="Email"
            class="shape email col-5"
          ></b-form-input>
          <b-form-input
            v-model="email"
             :state="stateOfConfirmEmail"
            type="email"
            placeholder="Confirm email"
            class="shape email col-5"
          ></b-form-input>
        </div>
        <div class="row mt-4 rowmargin">
          <b-form-input
            v-model="form.password"
            :state="stateOfPassword"
            type="password"
            placeholder="Password"
            class="shape password eye col-5"
          ></b-form-input>
          <b-form-input
            :state="stateOfConfirmPassword" 
            v-model="password"
            type="password"
            placeholder="Confirm password"
            class="shape password eye col-5"
          ></b-form-input>
        </div>
        <div class="row mt-4 rowmargin">
          <b-form-input
            v-model="form.companyName"
            type="text"
            placeholder="Company"
            class="shape company col-5"
          ></b-form-input>
          <b-form-input
            v-model="form.country"
            type="text"
            placeholder="Country"
            class="shape country col-5"
          ></b-form-input>
        </div>
         <br>
         <br>
         <b-form-checkbox
          id="terms-of-use"
          v-model="checkBoxstatus"
          name="termsOfUse"
          value="accepted"
          unchecked-value="not_accepted"
          >
          I agree to the <a href="https://altosio.com/terms-and-conditions/" target="_blank">  Terms and Conditions </a>
        </b-form-checkbox>
      
       
        <b-button
          type="submit"
          variant="primary"
          class="botton"
          :disabled="disabledProperty"
          >Register</b-button
        >
        <div class="mt-5 hint">
          <p>
            Already have an account?
            <span class="signin" v-on:click="login()">Sign In here</span>
          </p>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import api from "../api/index";

export default {
  name: "Register",
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        password: "",
        phoneNumber: "",
        country: "",
      },
      email: "",
      password: "",
      logged: false,
      registrationMessage: "",
      registrationError: false,
      registrationSuccess: false,
      isLoading: false,
      checkBoxstatus: ""
    };
  },
  computed: {
    stateOfConfirmPassword(){
      if(this.password== this.form.password){
        return true;
      }
      else{
        return false;
      }
    },
    stateOfPassword(){
       const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
       if(this.form.password.match(regex) === null){
         return false;
       }
       else{
         return true;
       }
    },
    stateOfConfirmEmail(){
      if(this.email == this.form.email){
        return true;
      }
      else{
        return false;
      }
    },
    stateOfEmail(){
       if(this.form.email.includes('@')){
         return true;
       }
       else{
         return false;
       }
    },
    disabledProperty() {
      if (
        this.form.firstName === "" ||
        this.form.lastName === "" ||
        this.form.companyName === "" ||
        this.form.email === "" ||
        this.form.password === "" ||
        this.form.country === "" ||
        this.checkBoxstatus!=="accepted"

      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.logged = this.$store.getters.isAuthenticated;
  },
  methods: {
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    onSubmit(event) {
      this.registrationError = false;
      this.isLoading = true;
      event.preventDefault();
      var validationMessage = this.validateInput();
      if (validationMessage === "") {
        api.register(this.form).then(
          () => {
            this.registrationSuccess = true;
            this.isLoading = false;
            this.sleep(10000).then(() => {
              this.$router.push({ path: "/login" });
            });
          },
          (error) => {
            this.registrationError = true;
            this.registrationMessage = error.responseData;
            this.isLoading = false;
          }
        );
      } else {
        this.registrationError = true;
        this.registrationMessage = validationMessage;
        this.isLoading = false;
      }
    },
    validateInput() {
      var message = "";
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
      if (this.email != this.form.email) {
        message = "Email and Confirm email fields do not match.";
      } else if (this.password != this.form.password) {
        message = "Password and Confirm password fields do not match.";
      } else if (this.form.password.match(regex) === null) {
        message = "Password must be between 6 and 20 characters and must contain upper and lower case letters and at least one numeric digit.";
      } else if (
        this.email.toLowerCase().includes("gmail") ||
        this.email.toLowerCase().includes("yahoo") ||
        this.email.toLowerCase().includes("aol") ||
        this.email.toLowerCase().includes("hotmail") ||
        this.email.toLowerCase().includes("ymail")
      ) {
        message = "Please use your corporate email address to register.";
      }
      return message;
    },
    login() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.all {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #f2f6ff;
  text-align: center;
}
.img {
  margin: auto;

  width: 253px;
  height: 75px;
  margin-top: 80px;
}
.box {
  margin: auto;
  width: 1100px;
  height: 730px;
  margin-top: 35px;

  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 4px 4px 15px rgba(225, 225, 225, 0.5);
}
.title {
  margin: auto;
  margin-top: 50px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #23438e;
}
.rowmargin{
  margin-left: 0%;
}
.hint {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #859ec0;
}
.shape {
  margin-left: 55px;
  width: 472px;
  height: 56px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background-repeat: no-repeat;
  text-indent: 40px;
}
.profile {
  background-image: url("../assets/profile.png");
  background-position: 15px 15px;
}

.email {
  background-image: url("../assets/Vector.png");
  background-position: 15px 18px;
}
.password {
  background-image: url("../assets/lock.png");
  background-position: 15px 16px;
}
.company {
  background-image: url("../assets/company.png");
  background-position: 15px 16px;
}
.country {
  background-image: url("../assets/country.png");
  background-position: 15px 16px;
}
.botton {
  margin: auto;
  margin-top: 50px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.reset {
  margin-right: 75px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;

  color: #23438e;
  cursor: pointer;
}
.signin {
  font-weight: 600;
  color: #23438e;
  cursor: pointer;
}
</style>
